import React, {useEffect, useState} from "react";
import {
  Grid,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import dayjs from "dayjs";

import Container from "../../components/Container";
import Page from "../../components/Page";
import FormButton from "../../components/FormButton";
import FormTextInput from "../../components/FormTextInput";
import AddEditIncomeExpenseModal from "../../components/AddEditIncomeExpenseModal";
import UseDebounce from "../../components/UseDebounce";
import ConfirmAlertBox from "../../components/ConfirmAlertBox";
import AlertMsg from "../../components/AlertMsg";
import {MOBILE_VIEW} from "../../components/Sidebar";
import FormDatePicker from "../../components/FormDatePicker";

import {connect} from "react-redux";
import {DELETE_INCOME_EXPENSE_SUCCESS} from "../../redux/actions/types";
import {
  listIncomeExpense,
  deleteIncomeExpense,
  updateApiStatus,
} from "../../redux/actions/userDataActions";

import styles from "./IncomeExpense.module.css";

import "../../assets/css/custom.css";

import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PlusIcon from '@mui/icons-material/AddOutlined';
import ExclaimIcon from '@mui/icons-material/ErrorOutlineOutlined';

let type_filter = {
  income: {bgColor: "#dff9ec", color: "#39da8a"},
  expense: {bgColor: "#ffe5e5", color: "#ff5b5c"},
};

const IncomeExpense = (props) => {
  const [pageNum, setPageNum] = useState(1);
  const [search, setSearch] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [type, setType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedIncomeExpenseData, setSelectedIncomeExpenseData] = useState(null);
  const [confirmBoxVisible, setConfirmBoxVisible] = useState(false);
  const [totalListCount, setTotalListCount] = useState("");
  const [listCount, setListCount] = useState("");
  const [pageLimit, setPageLimit] = useState(50);
  const [profit, setProfit] = useState(0);

  const debouncedSearch = UseDebounce(search, 500);

  const navigate = useNavigate();

  useEffect(() => {
    if (props.apiStatus == DELETE_INCOME_EXPENSE_SUCCESS && confirmBoxVisible) {
      setSelectedIncomeExpenseData(null);
      setConfirmBoxVisible(false);
      props.updateApiStatus("");
    }
  }, [props.apiStatus]);

  useEffect(() => {
    setTotalListCount(props.incomeExpenseTotalList);
  }, [props.incomeExpenseTotalList]);

  useEffect(() => {
    setListCount(props.incomeExpenseList?.length);
  }, [props.incomeExpenseList?.length]);

  useEffect(() => {
    getIncomeExpenseList(1, startDate, endDate);
  }, [debouncedSearch]);

  useEffect(() => {
    getIncomeExpenseList(1, startDate, endDate);
  }, []);

  useEffect(() => {
    if (startDate || endDate) {
      let total_income = 0;
      let total_expense = 0;
      let profit_val = 0;
      if (props?.incomeExpenseList?.length > 0) {
        total_income = props.incomeExpenseList?.filter((x) => x.type == 'income').reduce((sum, num) => sum + Number(num.amount), 0);
        total_expense = props.incomeExpenseList?.filter((x) => x.type == 'expense').reduce((sum, num) => sum + Number(num.amount), 0);
        profit_val = parseFloat(total_income) - parseFloat(total_expense);
      }
      setProfit(profit_val);
    }
  }, [props?.incomeExpenseList]);

  const getIncomeExpenseList = (page, startDate, endDate) => {
    let page1 = page;
    if (startDate || endDate) {
      page1 = "";
    }
    let data = {
      page: page1,
      searchIncomeExpense: search,
      startDate: startDate ? dayjs(startDate).format("YYYY-MM-DD") : '',
      endDate: endDate ? dayjs(endDate).format("YYYY-MM-DD") : '',
    };
    props.listIncomeExpense(JSON.stringify(data));
    setPageNum(page);
  };

  const onAddEditIncomeExpenseBtnClick = (e, val, item) => {
    e.stopPropagation();
    setSelectedIncomeExpenseData(item);
    setType(val);
    setModalVisible(true);
  };

  const onAddEditIncomeExpense = () => {
    setSelectedIncomeExpenseData(null);
    setModalVisible(false);
  };

  const onCancelIncomeExpense = () => {
    setSelectedIncomeExpenseData(null);
    setModalVisible(false);
  };

  const onSelectPage = (page) => {
    getIncomeExpenseList(page, startDate, endDate);
  };

  const onChangeDate = (val, type) => {
    let start_date = '';
    let end_date = '';
    if (type == 'start') {
      setStartDate(val);
      if(endDate == '' || endDate == null) {
        let date = new Date(val);
        let start_month = date.getMonth() + 1;
        let start_year = date.getFullYear();
        let currentdate = new Date();
        let cur_month = currentdate.getMonth() + 1;
        let cur_year = currentdate.getFullYear();
        let last_day = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        let end_last_day = dayjs(last_day)
        if((start_month == cur_month) && (start_year == cur_year)) {
          end_last_day = dayjs()
        }
        setEndDate(end_last_day);
        end_date = end_last_day
      } else {
        end_date = endDate
      }
      start_date = val;
    } else if (type == 'end') {
      setEndDate(val);
      start_date = startDate;
      end_date = val
    }
    getIncomeExpenseList(1, start_date, end_date)
  };

  const onSearchIncomeExpense = (val) => {
    setSearch(val);
  };

  const onDeleteIncomeExpenseBtnClick = (e, item) => {
    e.stopPropagation();
    setSelectedIncomeExpenseData(item);
    setConfirmBoxVisible(true);
  };

  const onConfirmDeleteIncomeExpense = () => {
    let data = {
      incomeExpenseUid: selectedIncomeExpenseData?.uid,
    };
    props.deleteIncomeExpense(JSON.stringify(data));
  };

  const onCancelDeleteIncomeExpense = () => {
    setSelectedIncomeExpenseData(null);
    setConfirmBoxVisible(false);
  };

  return (
    <Container page={"income_expense"}>
      <Grid container item md={12} xs={12}>
        <Grid
          item
          md={12}
          xs={12}
          container
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          className={styles.cardView}
        >
          <TableContainer className={styles.tableView}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={12}>
                    <Grid
                      display={"flex"}
                      flexDirection={"row"}
                      alignItems={'center'}
                      mt={2}
                    >
                      {(startDate || endDate) ?
                        <Grid item md={3} xs={3}
                              display={"flex"}
                              flexDirection={"row"}
                              alignItems={'center'}
                        >
                          <Typography className={styles.profitTitleText} mr={1}>{profit >= 0 ? 'PROFIT:': 'LOSS:'}</Typography>
                          <Typography className={styles.profitText}>{profit?.toFixed(2)}</Typography>
                        </Grid>
                        :
                        <Grid item md={3} xs={3}
                              display={"flex"}
                              flexDirection={"row"}
                              alignItems={'center'}
                        />}
                      <Grid item md={2} xs={2} mr={1} mt={-3}>
                        <FormDatePicker
                          page={"IncomeExpense"}
                          label={"Start Date"}
                          value={startDate}
                          inputFormat="DD-MM-YYYY"
                          maxDate={endDate ? dayjs(endDate) : dayjs()}
                          onChange={(val) => onChangeDate(val, 'start')}
                        />
                      </Grid>
                      <Grid item md={2} xs={2} mr={1} mt={-3}>
                        <FormDatePicker
                          page={"IncomeExpense"}
                          label={"End Date"}
                          value={endDate}
                          inputFormat="DD-MM-YYYY"
                          minDate={startDate ? dayjs(startDate) : dayjs()}
                          maxDate={dayjs()}
                          onChange={(val) => onChangeDate(val, 'end')}
                        />
                      </Grid>
                      <Grid item md={2} xs={2}>
                        <FormTextInput
                          page={"IncomeExpense"}
                          value={search}
                          setValue={(val) => onSearchIncomeExpense(val)}
                          height={40}
                          width={150}
                          placeholder={"Search.."}
                        />
                      </Grid>
                      <Grid item md={3} xs={2} ml={2}>
                        <FormButton
                          title={
                            MOBILE_VIEW ? (
                              <PlusIcon/>
                            ) : (
                              "Add Income/Expense"
                            )
                          }
                          startIcon={
                            MOBILE_VIEW ? null : (
                              <PlusIcon/>
                            )
                          }
                          btnStyleView={style.btnStyleView}
                          onSubmit={(e) =>
                            onAddEditIncomeExpenseBtnClick(e, "add", null)
                          }
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Patient</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Added Date</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.incomeExpenseLoading ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <CircularProgress/>
                    </TableCell>
                  </TableRow>
                ) : props.incomeExpenseList?.length > 0 ? (
                  props.incomeExpenseList?.map((item, index) => (
                    <TableRow
                      key={index}
                    >
                      <TableCell>{item?.title}</TableCell>
                      <TableCell>{item?.amount}</TableCell>
                      <TableCell>{item?.first_name && item?.last_name ? (item.first_name + ' ' + item.last_name) : (item?.first_name ? item.first_name : '-')}</TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            backgroundColor:
                              type_filter[item?.type]["bgColor"],
                          }}
                          className={styles.typeView}
                        >
                          <Typography
                            sx={{color: type_filter[item.type]["color"]}}
                            className={styles.typeText}
                          >
                            {item?.type}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        {moment(item?.added_date).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell>
                        <Box className={styles.iconMainView}>
                          <Box
                            onClick={(e) =>
                              onAddEditIncomeExpenseBtnClick(e, "edit", item)
                            }
                            className={styles.iconView}
                          >
                            <EditIcon fontSize="small"/>
                          </Box>
                          <Box
                            onClick={(e) => onDeleteIncomeExpenseBtnClick(e, item)}
                            ml={1}
                            className={styles.iconView}
                          >
                            <DeleteIcon fontSize="small"/>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <Typography className={styles.noMatchFoundText}>
                        No Income/Expense found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {(startDate || endDate) ? null :
            <Grid
              item
              md={12}
              xs={12}
              display={"flex"}
              flexDirection={{md: "row", xs: "column"}}
              alignItems={"center"}
            >
              <Grid
                item
                md={6}
                xs={12}
                display={"flex"}
                justifyContent={{md: "flex-start", xs: "unset"}}
                mt={2}
                mb={{md: 2, xs: 0}}
                pl={{md: 2, xs: 0}}
              >
                <Typography className={styles.showingEntriesText}>
                  Showing{" "}
                  {listCount == 0
                    ? 0
                    : pageNum == 1
                      ? 1
                      : parseInt((pageNum - 1) * pageLimit) + 1}{" "}
                  to{" "}
                  {listCount == 0
                    ? 0
                    : parseInt((pageNum - 1) * pageLimit) + listCount}{" "}
                  of {totalListCount} entries
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                display={"flex"}
                justifyContent={{md: "flex-end", xs: "unset"}}
                mt={2}
                mb={2}
                pr={{md: 2, xs: 0}}
              >
                <Page
                  totalPages={props.incomeExpenseTotalPages}
                  pageNum={pageNum}
                  onSelectPage={onSelectPage}
                />
              </Grid>
            </Grid>}

        </Grid>

      </Grid>

      <AddEditIncomeExpenseModal
        type={type}
        data={selectedIncomeExpenseData}
        modalVisible={modalVisible}
        onCancel={onCancelIncomeExpense}
        onSubmit={onAddEditIncomeExpense}
      />

      <ConfirmAlertBox
        confirmBox={confirmBoxVisible}
        icon={<ExclaimIcon sx={{height: 80, width: 80, color: '#ffc700'}}/>}
        title={"Are you sure?"}
        content={"You won't be able to revert this!"}
        btnLoading={props.incomeExpenseBtnLoading}
        firstBtn={"Yes, delete it!"}
        secondBtn={"Cancel"}
        onConfirmClick={onConfirmDeleteIncomeExpense}
        onCancelClick={onCancelDeleteIncomeExpense}
      />

      <AlertMsg/>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    incomeExpenseTotalPages: state.userData.incomeExpenseTotalPages,
    incomeExpenseTotalList: state.userData.incomeExpenseTotalList,
    incomeExpenseList: state.userData.incomeExpenseList,
    incomeExpenseLoading: state.userData.incomeExpenseLoading,
    incomeExpenseBtnLoading: state.userData.incomeExpenseBtnLoading,
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listIncomeExpense: (data) => dispatch(listIncomeExpense(data)),
    deleteIncomeExpense: (data) => dispatch(deleteIncomeExpense(data)),
    updateApiStatus: (data) => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IncomeExpense);

const style = {
  btnStyleView: {
    height: 40,
    width: "100%",
  },
};
