import React, { useState } from "react";
import { Grid, Typography, Box, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import styles from "./FormDatePicker.module.css";

const FormDatePicker = (props) => {
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  return (
    <Grid item md={12} xs={12} container display={"flex"}>
      {props.label ? (
        <Grid
          display={"flex"}
          flexDirection={"row"}
          item
          md={12}
          xs={12}
          container
        >
          <Typography className={styles.labelText}>{props.label}</Typography>
          {props?.required ? (
            <Box>
              <Typography className={styles.requiredText} mt={-0.3} pl={0.3}>
                *
              </Typography>
            </Box>
          ) : null}
        </Grid>
      ) : null}
      <Grid
        sx={[
          style.textInputView,
          props?.disabled
            ? style.disabledTextInputBgView
            : style.textInputBgView,
        ]}
        className={[props.error ? styles.errorBorderStyle : styles.borderStyle]}
        item
        md={12}
        xs={12}
        container
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={props.value}
            format={props.inputFormat}
            onChange={props.onChange}
            open={datePickerOpen}
            onClose={() => setDatePickerOpen(false)}
            minDate={props?.minDate ? props.minDate : null}
            maxDate={props?.maxDate ? props.maxDate : null}
            slotProps={{
              actionBar: {
                actions: ['clear'],
              },
              textField: {
                variant: props?.variant ? props?.variant : "standard",
                disabled: props?.disabled ? props?.disabled : false,
                placeholder: props?.placeholder ? props?.placeholder : '',
                sx: {
                  "&.MuiTextField-root": {
                    width: "100%",
                  },
                  "& .MuiInput-root": {
                    "&:before, :after, :hover:not(.Mui-disabled):before": {
                      borderBottom: 0,
                    },
                    transition: "0.2s",
                    paddingRight: 2,
                  },
                  "& .Mui-focused": {
                    border: props?.error ? 'unset !important' : '1px solid #5a8dee !important',
                    height: props?.height ? props.height : 36,
                    borderRadius: 1,
                    transition: "0.2s",
                    paddingLeft: 2,
                  },
                  input: {
                    "&::placeholder": {
                      color: "#CCC",
                      fontSize: 14,
                      opacity: 1,
                    },
                  },
                },
                InputProps: {
                  autoComplete: "none",
                  sx: style.textInputValue,
                  endAdornment: null,
                },
                onClick: () => setDatePickerOpen(true),
              },
            }}
          />
        </LocalizationProvider>
      </Grid>
      {props.error ? (
        <Box mt={0.7} className={styles.errorView}>
          <Typography className={styles.errorText}>{props.error}</Typography>
        </Box>
      ) : null}
    </Grid>
  );
};

export default FormDatePicker;

const style = {
  textInputView: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFF",
    border: "1px solid #CCC",
    height: 36,
    borderRadius: 1,
  },
  textInputBgView: {
    backgroundColor: "#FFF",
  },
  disabledTextInputBgView: {
    backgroundColor: "#e9ecee",
  },
  textInputValue: {
    fontFamily: "IBMPlexSansRegular",
    color: "#000",
    fontSize: 14,
    px: 1.5,
  },
};
