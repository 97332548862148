import React, {useEffect, useState} from "react";
import {
  Grid,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import dayjs from "dayjs";
import {Link} from "react-router-dom";
import CsvDownloader from 'react-csv-downloader';

import Container from "../../components/Container";
import Page from "../../components/Page";
import FormTextInput from "../../components/FormTextInput";
import UseDebounce from "../../components/UseDebounce";
import AlertMsg from "../../components/AlertMsg";
import SelectPatientModal from "../../components/SelectPatientModal";
import FormButton from "../../components/FormButton";
import {MOBILE_VIEW} from "../../components/Sidebar";
import FormDatePicker from "../../components/FormDatePicker";

import {connect} from "react-redux";
import {
  listInvoice,
} from "../../redux/actions/userDataActions";

import styles from "./Invoices.module.css";

import "../../assets/css/custom.css";

import PlusIcon from "@mui/icons-material/AddOutlined";

const columns = [{
  id: 'invoice_date',
  displayName: 'Date'
}, {
  id: 'item',
  displayName: 'Item'
}, {
  id: 'quantity',
  displayName: 'Quantity'
}, {
  id: 'price',
  displayName: 'Unit Price'
}, {
  id: 'discount_in_pct',
  displayName: 'Discount'
}, {
  id: 'gst_in_pct',
  displayName: 'GST(%)'
}, {
  id: 'sgst',
  displayName: 'SGST'
}, {
  id: 'cgst',
  displayName: 'CGST'
}, {
  id: 'total_taxable',
  displayName: 'Total Taxable'
}, {
  id: 'total',
  displayName: 'Total'
}];

const Invoices = (props) => {
  const [pageNum, setPageNum] = useState(1);
  const [search, setSearch] = useState("");
  const [totalListCount, setTotalListCount] = useState("");
  const [listCount, setListCount] = useState("");
  const [pageLimit, setPageLimit] = useState(50);
  const [modalVisible, setModalVisible] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [invoiceItemList, setInvoiceItemList] = useState([]);

  const debouncedSearch = UseDebounce(search, 500);

  const navigate = useNavigate();

  useEffect(() => {
    setTotalListCount(props.invoiceTotalList);
  }, [props.invoiceTotalList]);

  useEffect(() => {
    setListCount(props.invoiceList?.length);
  }, [props.invoiceList?.length]);

  useEffect(() => {
    let list = [];
    props.invoiceList?.map((item, index) => {
      item?.invoice_item?.map((item1, index1) => {

        let price = 0;
        let total_taxable_price = 0;
        let total_price = 0;
        let gst = 0;
        let sgst = 0;
        let cgst = 0;
        if (item1?.price) {
          price = item1?.price
          if (item1?.discount_in_pct > 0) {
            price = parseFloat(item1.price) - parseFloat(item1.discount_in_pct);
          }

          if (item1?.gst_in_pct) {
            gst = gst + (parseFloat(price) * parseFloat(item1?.quantity ? item1.quantity : 1)) * (item1?.gst_in_pct / 100);
            sgst = gst / 2;
            cgst = gst / 2;
          }

          if (item1?.quantity > 0) {
            total_taxable_price = Number(item1?.quantity) * price;
            total_price = parseFloat(total_taxable_price) + parseFloat(gst)
          }
        }

        let data =
          {
            ...item1,
            invoice_date: dayjs(item?.invoice_date).format('DD-MM-YYYY'),
            sgst: sgst,
            cgst: cgst,
            total_taxable: total_taxable_price > 0 ? total_taxable_price?.toFixed(2) : 0,
            total: total_price > 0 ? total_price?.toFixed(2) : 0
          }
        list.push(data)
      })
    });
    setInvoiceItemList(list)
  }, [props.invoiceList])

  useEffect(() => {
    getInvoiceList(1);
  }, [debouncedSearch]);

  useEffect(() => {
    if (((startDate != null && startDate != '') && (endDate != null && endDate != ''))
      || ((startDate == null || startDate == '') && (endDate == null || endDate == ''))) {
      getInvoiceList(1);
    }
  }, [startDate, endDate]);

  const getInvoiceList = (page) => {
    let data = {
      page: page,
      searchInvoice: search,
      startDate: (startDate != null && startDate != '') ? dayjs(startDate).format("YYYY-MM-DD") : null,
      endDate: (endDate != null && endDate != '') ? dayjs(endDate).format("YYYY-MM-DD") : null,
    };
    props.listInvoice(JSON.stringify(data));
    setPageNum(page);
  };

  const onSelectPage = (page) => {
    getInvoiceList(page);
  };

  const onSearchInvoice = (val) => {
    setSearch(val);
  };

  const onViewClick = (item) => {
    navigate("/invoice/" + item?.uid);
  };

  const onAddInvoiceBtnClick = () => {
    setModalVisible(true);
  };

  const onSubmitPatient = (data) => {
    setModalVisible(false)
    navigate("/invoice?patient_id=" + data?.uid);
  };

  const onClosePatient = () => {
    setModalVisible(false)
  };

  return (
    <Container page={"invoices"}>
      <Grid container item md={12} xs={12}>
        <Grid
          item
          md={12}
          xs={12}
          container
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          className={styles.cardView}
        >
          <TableContainer className={styles.tableView}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={7}>
                    <Grid container item md={12} xs={12} display={"flex"}
                          flexDirection={"row"} justifyContent={'space-between'}>
                      <Grid item md={2.6} xs={12} mt={2}>
                        <Grid item md={12} xs={12} display={'flex'} justifyContent={'flex-start'}>
                          <FormTextInput
                            page={"Invoices"}
                            value={search}
                            setValue={(val) => onSearchInvoice(val)}
                            height={40}
                            width={150}
                            placeholder={"Search.."}
                          />
                        </Grid>
                      </Grid>
                      <Grid item md={9} xs={12} display={"flex"}
                            flexDirection={"row"} justifyContent={'flex-end'} mt={2}>
                        {((startDate != null && startDate != '') && (endDate != null && endDate != '') && (invoiceItemList?.length > 0)) ?
                          <Grid item md={2.6} xs={4.6} mr={1}
                                display={"flex"} justifyContent={'flex-end'}>
                            <CsvDownloader
                              filename={dayjs().format('DD-MM-YYYY')}
                              extension=".csv"
                              columns={columns}
                              datas={invoiceItemList}
                            >
                              <FormButton
                                title={'Download'}
                                btnStyleView={style.downloadBtnStyleView}
                                onSubmit={() =>
                                  null
                                }
                              />
                            </CsvDownloader>
                          </Grid>
                          : null}
                        <Grid item md={2.6} xs={3.6} mr={1}>
                          <FormDatePicker
                            page={"Invoices"}
                            placeholder={'Start Date'}
                            value={startDate}
                            inputFormat="DD-MM-YYYY"
                            maxDate={endDate ? dayjs(endDate) : null}
                            onChange={(newValue) => {
                              setStartDate(newValue);
                            }}
                          />
                        </Grid>
                        <Grid item md={2.6} xs={3.6} mr={1}>
                          <FormDatePicker
                            page={"Invoices"}
                            placeholder={'End Date'}
                            value={endDate}
                            minDate={startDate ? dayjs(startDate) : null}
                            inputFormat="DD-MM-YYYY"
                            onChange={(newValue) => {
                              setEndDate(newValue);
                            }}
                          />
                        </Grid>
                        <Grid item md={2.6} xs={3.6}>
                          <FormButton
                            title={
                              MOBILE_VIEW ? (
                                <PlusIcon/>
                              ) : (
                                "Add Invoice"
                              )
                            }
                            startIcon={
                              MOBILE_VIEW ? null : (
                                <PlusIcon/>
                              )
                            }
                            btnStyleView={style.btnStyleView}
                            onSubmit={() =>
                              onAddInvoiceBtnClick()
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableHead>
                <TableRow>
                  <TableCell>Invoice No</TableCell>
                  <TableCell>Invoice Date</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>Patient</TableCell>
                  <TableCell>Payment Method</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.invoiceLoading ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress/>
                    </TableCell>
                  </TableRow>
                ) : props.invoiceList?.length > 0 ? (
                  props.invoiceList?.map((item, index) => (
                    <TableRow
                      component={Link}
                      to={"/invoice/" + item?.uid}
                      key={index}
                      onClick={(e) => onViewClick(item)}
                      className={styles.tableRowView}
                    >
                      <TableCell>#{item?.invoice_no}</TableCell>
                      <TableCell>
                        {item?.invoice_date ? moment(item.invoice_date).format(
                          "DD-MM-YYYY"
                        ) : '-'}</TableCell>
                      <TableCell>₹{item?.total}</TableCell>
                      <TableCell>
                        {(item?.patient?.first_name && item?.patient?.last_name) ?
                          (item.patient.first_name + ' ' + item.patient.last_name)
                          : (item?.patient?.first_name ?
                            item.patient.first_name
                            : '-')}
                      </TableCell>
                      <TableCell
                        className={styles.itemPaymentMethod}>{item?.payment_method ? item.payment_method : '-'}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Typography className={styles.noMatchFoundText}>
                        No Invoices found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {((startDate != null && startDate != '') && (endDate != null && endDate != '')) ? null
            : <Grid
              item
              md={12}
              xs={12}
              display={"flex"}
              flexDirection={{md: "row", xs: "column"}}
              alignItems={"center"}
            >
              <Grid
                item
                md={6}
                xs={12}
                display={"flex"}
                justifyContent={{md: "flex-start", xs: "unset"}}
                mt={2}
                mb={{md: 2, xs: 0}}
                pl={{md: 2, xs: 0}}
              >
                <Typography className={styles.showingEntriesText}>
                  Showing{" "}
                  {listCount == 0
                    ? 0
                    : pageNum == 1
                      ? 1
                      : parseInt((pageNum - 1) * pageLimit) + 1}{" "}
                  to{" "}
                  {listCount == 0
                    ? 0
                    : parseInt((pageNum - 1) * pageLimit) + listCount}{" "}
                  of {totalListCount} entries
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                display={"flex"}
                justifyContent={{md: "flex-end", xs: "unset"}}
                mt={2}
                mb={2}
                pr={{md: 2, xs: 0}}
              >
                <Page
                  totalPages={props.invoiceTotalPages}
                  pageNum={pageNum}
                  onSelectPage={onSelectPage}
                />
              </Grid>
            </Grid>}

        </Grid>
      </Grid>

      <SelectPatientModal
        modalVisible={modalVisible}
        onClose={onClosePatient}
        onSubmit={onSubmitPatient}
      />
      <AlertMsg/>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    invoiceTotalPages: state.userData.invoiceTotalPages,
    invoiceTotalList: state.userData.invoiceTotalList,
    invoiceList: state.userData.invoiceList,
    invoiceLoading: state.userData.invoiceLoading,
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listInvoice: (data) => dispatch(listInvoice(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);

const style = {
  btnStyleView: {
    height: 37,
    width: "100%",
  },
  downloadBtnStyleView: {
    height: 37,
    width: "100%",
  }
};
